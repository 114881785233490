import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger
} from '@fluentui/react-components';
import { DeleteRegular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { DeleteProjectDialogRef } from './DeleteProjectDialog';
import { DeleteProjectOption } from './DeleteProjectOption';
import { ProjectSelectionDialogRef } from './ProjectSelectionDialog';
import { ProjectSelectionSubMenu } from './ProjectSelectionSubMenu';

interface IProjectDeletionSubMenuProps {
    projectId: string;
    projectSelectionAction: (projectId: string) => void;
    // Need ref passed as parameter since the submenu will go out of scope when the submenu closes
    projectSelectionDialogRef: React.RefObject<ProjectSelectionDialogRef>;
    deleteProjectDialogRef: React.RefObject<DeleteProjectDialogRef>;
}

export const ProjectDeletionSubMenu: React.FC<IProjectDeletionSubMenuProps> = ({
    projectId,
    projectSelectionAction,
    projectSelectionDialogRef,
    deleteProjectDialogRef,
}) => {
    const [open, setOpen] = useState(false);
    const deleteAndRetainText = 'This action will permanently delete the project and any associated conversations will no longer belong to a project.';
    const deleteAndDeleteText = 'This action will permanently delete the project and permanently delete all conversations in this project.';

    return (
        <>
            <Menu
                open={open}
                onOpenChange={(_event, data) => {
                    setOpen(data.open);
                }}
            >
                <MenuTrigger>
                    <MenuItem icon={<DeleteRegular />}>Delete</MenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                deleteProjectDialogRef.current?.openDialog(deleteAndRetainText, DeleteProjectOption.DeleteAndRetainChats);
                            }}
                        >
                            Delete and retain chats
                        </MenuItem>
                        <ProjectSelectionSubMenu
                            menuText='Delete and move chats'
                            projectSelectionAction={projectSelectionAction}
                            currentProjectId={projectId}
                            projectSelectionDialogRef={projectSelectionDialogRef}
                        />
                        <MenuItem
                            onClick={() => {
                                deleteProjectDialogRef.current?.openDialog(deleteAndDeleteText, DeleteProjectOption.DeleteAndDeleteLeaveChats);
                            }}
                        >
                            Delete and delete/leave chats
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};
