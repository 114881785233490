import * as iconModule from '@fluentui/react-icons';
import { getIconColorByBackgroundColor } from './PersonaIconColorUtils';

export const getIconByName = (
    iconName: string | undefined,
    size?: number | undefined,
    iconBackgroundColor?: string | undefined
): JSX.Element | null => {
    if (!iconName)
        return null;

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const IconComponent: any = iconModule[iconName as keyof typeof iconModule];
        const iconStyle: React.CSSProperties = {};
        if (size) {
            iconStyle.fontSize = `${size - 12}px`;
        }
        if (iconBackgroundColor) {
            iconStyle.color = getIconColorByBackgroundColor(iconBackgroundColor);
        }

        if (IconComponent) {
            // Default font size of 32px avatar is 20px, so we need to adjust the size
            return <IconComponent style={iconStyle} />;
        } else {
            console.warn(`Icon "${iconName}" not found in @fluentui/react-icons`);
            return null;
        }
    } catch (error) {
        console.error(`Error loading icon "${iconName}":`, error);
        return null;
    }
};

export const getIconByNameNoColorOrBackground = (
    iconName: string | undefined
): JSX.Element | null => {
    if (!iconName)
        return null;

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const IconComponent: any = iconModule[iconName as keyof typeof iconModule];

        if (IconComponent) {
            // Default font size of 32px avatar is 20px, so we need to adjust the size
            return <IconComponent />;
        } else {
            console.warn(`Icon "${iconName}" not found in @fluentui/react-icons`);
            return null;
        }
    } catch (error) {
        console.error(`Error loading icon "${iconName}":`, error);
        return null;
    }
};