import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { ProjectCreateButton } from './ProjectCreateButton';
import { ProjectListItem } from './ProjectListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
    },
    header: {
        marginTop: 0,
        paddingBottom: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
    },
});

interface IProjectListSectionProps {
    pinnedProjectConversations?: Conversations;
    latestProjectConversations?: Conversations;
    olderProjectConversations?: Conversations;
    isFiltering: boolean;
    filterText: string;
    isCollapsed: boolean;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export const ProjectListSection: React.FC<IProjectListSectionProps> = ({
    pinnedProjectConversations,
    latestProjectConversations,
    olderProjectConversations,
    isFiltering,
    filterText,
    isCollapsed,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit,
}) => {
    const classes = useClasses();
    const projects = useAppSelector((state: RootState) => state.projects.projects);

    return (
        <>
            <div className={classes.root}>
                {Object.keys(projects).sort((a, b) => projects[a].name.localeCompare(projects[b].name)).map((id) => {
                    const pinned = Object.values(pinnedProjectConversations ?? {}).filter(x => x.projectId === id);
                    const latest = Object.values(latestProjectConversations ?? {}).filter(x => x.projectId === id);
                    const older = Object.values(olderProjectConversations ?? {}).filter(x => x.projectId === id);
                    return (!isFiltering || !filterText || pinned.length > 0 || latest.length > 0 || older.length > 0)
                        ?
                            <ProjectListItem
                                id={id}
                                key={id}
                                pinnedProjectConversationIds={pinned.map(x => x.id)}
                                latestProjectConversationIds={latest.map(x => x.id)}
                                olderProjectConversationIds={older.map(x => x.id)}
                                isCollapsed={isCollapsed}
                                isConversationPaneFill={isConversationPaneFill}
                                closeConversationPanel={closeConversationPanel}
                                setIsEdit={setIsEdit}
                            />
                        :
                            null;
                })}
            </div>
            {!isCollapsed && Object.keys(projects).length === 0 && 
                <ProjectCreateButton />
            }
        </>
    );
};
