import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label, makeStyles, mergeClasses, SelectTabEventHandler, shorthands, Tab, TabList, tokens } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { PersonaType } from '../../../libs/models/Persona';
import { useAppDispatch } from '../../../redux/app/hooks';
import { store } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { RightHandPanel } from '../../../redux/features/app/AppState';
import { PersonaData } from '../../../redux/features/personas/PersonaState';
import { openProjectFolder } from '../../../redux/features/projects/projectSlice';
import { SharedStyles } from '../../../styles';
import { PersonaCardSmallClickable } from '../persona/PersonaCardSmallClickable';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    error: {
        color: '#d13438',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
        tabList: {
        justifyContent: 'center',
    },
    cardsRoot: {
        marginTop: tokens.spacingVerticalXXXL,
        marginBottom: tokens.spacingVerticalXL,
    },
    cards: {
        gap: '16px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    tabDefaultTextContainer: {
        textAlign: 'center',
    },
});

interface IProjectPersonaSelectionDialogProps {
    projectId: string;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export interface ProjectPersonaSelectionDialogRef {
    openDialog: () => void;
}

export const ProjectPersonaSelectionDialog = forwardRef<ProjectPersonaSelectionDialogRef, IProjectPersonaSelectionDialogProps>(({
    projectId,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit
},
    ref
) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [projectName, setProjectName] = useState('');
    const [loadedPersonas, setLoadedPersonas] = useState<PersonaData[]>([]);
    
    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load project data from redux store
            setProjectName(store.getState().projects.projects[projectId].name);

            // Load personas from redux store
            const currentUserId = store.getState().app.activeUserInfo?.id;
            const allPersonas = Object.values(store.getState().personas.personas).filter(
                (persona) => persona.creatorUserId === currentUserId || persona.type === PersonaType.Global);
            setLoadedPersonas(allPersonas);

            setOpen(true);
        },
    }));
        
    const [selectedTab, setSelectedTab] = useState<PersonaType>(PersonaType.User);
    const onTabSelect: SelectTabEventHandler = (_event, data) => {
        setSelectedTab(data.value as PersonaType);
    };

    const [currentTabPersonas, setCurrentTabPersonas] = useState<PersonaData[]>([]);
    useEffect(() => {
        // Get the values out of loadedPersonas that have the type = selectedTab
        const personasOfType = loadedPersonas.filter((persona) => persona.type === selectedTab);
        personasOfType.sort((a, b) => a.name.localeCompare(b.name));
        setCurrentTabPersonas(personasOfType);
    }, [loadedPersonas, selectedTab]);

    const createNewChatWithPersona = (personaId: string) => {
        if (submitting) {
            return;
        }
        setSubmitting(true);

        setIsEdit(false);
        void chat.createChat(personaId, projectId);
        dispatch(openProjectFolder(projectId));
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));

        if (isConversationPaneFill) {
            closeConversationPanel();
        }

        setOpen(false);
    };

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                appearance="subtle"
                                aria-label="close"
                                icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        Select A Persona To Start A Chat In &quot;{projectName}&quot;:
                    </DialogTitle>
                    <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                        <TabList selectedValue={selectedTab} onTabSelect={onTabSelect} className={classes.tabList}>
                            <Tab value={PersonaType.User}>My Personas</Tab>
                            <Tab value={PersonaType.Global}>Eastman Personas</Tab>
                        </TabList>
                        <div className={classes.cardsRoot}>
                            {currentTabPersonas.length === 0
                                ?
                                <div className={classes.tabDefaultTextContainer}>
                                    <Label>
                                        {selectedTab === PersonaType.User &&
                                            'You do not have any custom personas at this time.'}
                                        {selectedTab === PersonaType.Global &&
                                            'There are no shared Eastman Personas at this time.'}
                                    </Label>
                                </div>
                                :
                                <div className={classes.cards}>
                                    {currentTabPersonas.map((persona) => {
                                        return (
                                            <PersonaCardSmallClickable
                                                key={persona.id}
                                                personaId={persona.id}
                                                personaOnClick={() => {
                                                    createNewChatWithPersona(persona.id);
                                                }}
                                            />
                                        );
                                    })}         
                                </div>
                            }
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

ProjectPersonaSelectionDialog.displayName = 'ProjectPersonaSelectionDialog';