import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { Add32Light } from '@fluentui/react-icons';
import { FC } from 'react';
import { SharedStyles } from '../../../styles';
import { NewProject } from '../project/NewProject';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        //...Breakpoints.small({
        //    justifyContent: 'center',
        //}),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
        },
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    iconRoot: {
        marginRight: tokens.spacingHorizontalS,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    }
});

export const ProjectCreateButton: FC = () => {
    const classes = useClasses();
    
    return (
        <NewProject
            TriggerComponent={
                <div
                    className={classes.root}
                    onClick={() => {}}
                    aria-label={'Create new project folder'}
                >
                    <div className={classes.iconRoot}>
                        <Add32Light />
                    </div>
                    
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text className={classes.title}>
                                Create A Project Folder
                            </Text>
                        </div>
                    </div>
                </div>
            }
        />
    );
};
