import { Button, makeStyles, shorthands, Text, ToggleButton, tokens } from '@fluentui/react-components';
import { CommentAdd20Regular, FolderOpenRegular, FolderRegular, MoreHorizontal20Regular } from '@fluentui/react-icons';
import { FC, useRef } from 'react';
import { useChat } from '../../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { RightHandPanel } from '../../../redux/features/app/AppState';
import { closeProjectFolder, openProjectFolder } from '../../../redux/features/projects/projectSlice';
import { SharedStyles } from '../../../styles';
import { ProjectOptionsMenu } from '../project/ProjectOptionsMenu';
import { ChatListSection } from './ChatListSection';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground4Hover,
            paddingRight: '74px', // 10px + 32px wide (options button) + 32px wide (new chat button)
            '& button:not(:first-child)': {
                display: 'block !important',
            },
        },
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXL,
            tokens.spacingVerticalXS, tokens.spacingHorizontalS),
    },
    rootSmall: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXL),
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    optionsButton: {
        display: 'none',
        position: 'absolute',
        right: '42px', // 10px + 32px wide
        zIndex: 1,
    },
    newChatButton: {
        display: 'none',
        position: 'absolute',
        right: '10px',
        zIndex: 1,
    },
    projectConversations: {
        marginLeft: '20px',
        paddingLeft: '5px',
        borderLeft: `${tokens.colorNeutralForeground2BrandSelected} solid 1px`,
    },
    projectConversationsCollapsed: {
        marginLeft: '5px',
        paddingLeft: '5px',
        borderLeft: `${tokens.colorNeutralForeground2BrandSelected} solid 1px`,
    },
});

interface IProjectListItemProps {
    id: string;
    pinnedProjectConversationIds: string[];
    latestProjectConversationIds: string[];
    olderProjectConversationIds: string[];
    isCollapsed: boolean;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export const ProjectListItem: FC<IProjectListItemProps> = ({
    id,
    pinnedProjectConversationIds,
    latestProjectConversationIds,
    olderProjectConversationIds,
    isCollapsed,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit
}) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const name: string = useAppSelector((state: RootState) => state.projects.projects[id].name);
    const folderOpen: boolean = useAppSelector((state: RootState) => state.projects.projects[id].folderOpen);

    const rootRef = useRef<HTMLDivElement>(null);
    const newChatWithProject = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
            return;
        }
        
        setIsEdit(false);
        void chat.createChat(undefined, id);
        if (!folderOpen) {
            dispatch(openProjectFolder(id));
        }
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));

        if (isConversationPaneFill) {
            closeConversationPanel();
        }
    };

    const onClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        if (rootRef.current && !rootRef.current.contains(event.target as Node)) {
            return;
        }

        dispatch(folderOpen ? closeProjectFolder(id) : openProjectFolder(id));
    };

    return (
        <>
            <div
                className={!isCollapsed ? classes.root : classes.rootSmall}
                onClick={onClick}
                title={`Project: ${name}`}
                aria-label={`Project list item: ${name}`}
                ref={rootRef}
            >
                <ToggleButton
                    appearance="transparent"
                    checked={folderOpen}
                    onClick={() => { dispatch(folderOpen ? closeProjectFolder(id) : openProjectFolder(id)); }}
                    icon={ folderOpen ? <FolderOpenRegular /> : <FolderRegular /> }
                />
                {!isCollapsed &&
                    <>
                        <div className={classes.body}>
                            <div className={classes.header}>
                                <Text className={classes.title} title={name}>
                                    {name}
                                </Text>
                            </div>
                        </div>
                        <ProjectOptionsMenu
                            projectId={id}
                            isConversationPaneFill={isConversationPaneFill}
                            closeConversationPanel={closeConversationPanel}
                            setIsEdit={setIsEdit}
                            TriggerComponent={
                                <Button
                                    className={classes.optionsButton}
                                    appearance="transparent"
                                    icon={<MoreHorizontal20Regular />}
                                    title="More options"
                                    aria-label={`More options for project: ${name}`}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                            }
                        />
                        <Button
                            className={classes.newChatButton}
                            icon={<CommentAdd20Regular />}
                            appearance="transparent"
                            title="Create new chat in project"
                            aria-label={`Create new chat in project: ${name}`}
                            onClick={(event) => {
                                event.stopPropagation();
                                newChatWithProject(event);
                            }}
                        />
                    </>
                }
            </div>
            {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
            {folderOpen && (pinnedProjectConversationIds.length > 0 || latestProjectConversationIds.length > 0 || olderProjectConversationIds.length > 0) &&
                <div className={isCollapsed ? classes.projectConversationsCollapsed : classes.projectConversations}>
                    {pinnedProjectConversationIds.length > 0 &&
                        <ChatListSection
                            header="Pinned"
                            isCollapsed={isCollapsed}
                            isConversationPaneFill={isConversationPaneFill}
                            closeConversationPanel={closeConversationPanel}
                            keys={pinnedProjectConversationIds}
                            setIsEdit={setIsEdit}
                        />
                    }
                    {latestProjectConversationIds.length > 0 &&
                        <ChatListSection
                            header="Today"
                            isCollapsed={isCollapsed}
                            isConversationPaneFill={isConversationPaneFill}
                            closeConversationPanel={closeConversationPanel}
                            keys={latestProjectConversationIds}
                            setIsEdit={setIsEdit}
                        />
                    }
                    {olderProjectConversationIds.length > 0 &&
                        <ChatListSection
                            header="Older"
                            isCollapsed={isCollapsed}
                            isConversationPaneFill={isConversationPaneFill}
                            closeConversationPanel={closeConversationPanel}
                            keys={olderProjectConversationIds}
                            setIsEdit={setIsEdit}
                        />
                    }
                </div>
            }
        </>
    );
};
