import {
    Button,
    Carousel,
    CarouselCard,
    CarouselNav,
    CarouselNavButton,
    CarouselNavContainer,
    CarouselSlider,
    CarouselViewport,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    makeStyles,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import May2024 from '../../../documentation/new-releases/2024-05.md';
import Jul2024 from '../../../documentation/new-releases/2024-07.md';
import Aug2024 from '../../../documentation/new-releases/2024-08.md';
import Nov2024 from '../../../documentation/new-releases/2024-11.md';
import Jan2025 from '../../../documentation/new-releases/2025-01.md';

const useClasses = makeStyles({
    dialogBody: {
        maxHeight: 'calc(100dvh - 48px)',
        height: '700px',
        rowGap: 0,
    },
    dialogSurface: {
        maxHeight: '100dvh',
    },
    carousel: {
        padding: 0, 
        gap: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flexGrow: 1,
    },
    card: {
        overflowY: "scroll",
        overflowX: "hidden",
        flexGrow: 1,
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "auto",
        padding: `${tokens.spacingVerticalS} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL}`,
    },
    header: {
        display: "block",
        margin: `${tokens.spacingVerticalXXL} ${tokens.spacingVerticalXXL} ${tokens.spacingVerticalS} ${tokens.spacingVerticalXXL}`,
        ...typographyStyles.subtitle1,
    },
    text: {
        display: "block",
        padding: `${tokens.spacingVerticalS} ${tokens.spacingVerticalXXL}`,
        ...typographyStyles.body1,
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: 0,
    },
    break: {
        width: "100%",
    }
});

const pages = [
    { id: "Jan2025", markdown: Jan2025 },
    { id: "Nov2024", markdown: Nov2024 },
    { id: "Aug2024", markdown: Aug2024 },
    { id: "Jul2024", markdown: Jul2024 },
    { id: "May2024", markdown: May2024 },
];

export const NewFeatureDialog: React.FC = () => {
    const classes = useClasses();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Reset or initialize page on open if necessary
        if (isOpen) {
            setActiveIndex(0);
        }
    }, [isOpen]);

    const [carouselText, setCarouselText] = useState<string>('');
    useEffect(() => {
        void fetch(pages[activeIndex].markdown)
            .then((response) => response.text())
            .then((text) => { setCarouselText(text); });
    }, [activeIndex]);

    return (
        <Dialog open={isOpen}>
            <DialogSurface className={classes.dialogSurface}>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle>What&apos;s New</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <hr className={classes.break} />
                        <Carousel
                            className={classes.carousel}
                            groupSize={1}
                            //circular
                            activeIndex={activeIndex}
                            motion="fade"
                            onActiveIndexChange={(_, data: { index: number }) => { setActiveIndex(data.index); }}
                        >
                            <CarouselViewport className={classes.card}>
                                <CarouselSlider>
                                {pages.map((page) => (
                                    <CarouselCard key={page.id}>
                                        <ReactMarkdown>{carouselText}</ReactMarkdown>
                                    </CarouselCard>
                                ))}
                                </CarouselSlider>
                            </CarouselViewport>
                            <CarouselNavContainer
                                layout="inline"
                                next={{ "aria-label": "go to next" }}
                                prev={{ "aria-label": "go to previous" }}
                            >
                                <CarouselNav>
                                {(index) => (
                                    <CarouselNavButton aria-label={`Carousel Nav Button ${index}`} />
                                )}
                                </CarouselNav>
                            </CarouselNavContainer>
                        </Carousel>
                        <hr className={classes.break} />
                        <p>
                            The Eastman Copilot V2 Quick Reference Guide can be accessed{' '}
                            <Link href={process.env.REACT_APP_COPILOT_QRG_LINK} target="_blank">
                                here
                            </Link>{' '}
                            for more detailed information about Eastman Copilot&apos;s features.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
