import {
    Menu,
    MenuDivider,
    MenuGroup,
    MenuGroupHeader,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useChat } from '../../../libs/hooks';
import { PersonaType } from '../../../libs/models/Persona';
import { getIconByNameNoColorOrBackground } from '../../../libs/utils/PersonaIconComponentUtils';
import { store } from '../../../redux/app/store';
import { setActiveRightHandPanel } from '../../../redux/features/app/appSlice';
import { RightHandPanel } from '../../../redux/features/app/AppState';
import { PersonaData } from '../../../redux/features/personas/PersonaState';
import { openProjectFolder } from '../../../redux/features/projects/projectSlice';
import { ProjectPersonaSelectionDialogRef } from './ProjectPersonaSelectionDialog';

interface IProjectOptionsMenuProps {
    projectId: string;
    // Need ref passed as parameter since the submenu will go out of scope when the submenu closes
    projectPersonaSelectionDialogRef: React.RefObject<ProjectPersonaSelectionDialogRef>;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

interface PersonaDataSubMenuOptions {
    userPins: PersonaData[];
    globalPins: PersonaData[];
    userNonPins: PersonaData[];
    globalNonPins: PersonaData[];
}

export const NewProjectWithPersonaSubMenu: React.FC<IProjectOptionsMenuProps> = ({
    projectId,
    projectPersonaSelectionDialogRef,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit
}) => {
    const chat = useChat();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const shownPersonaLimit = 10;
    const [shownPersonas, setShownPersonas] = useState<PersonaDataSubMenuOptions>({
        userPins: [],
        globalPins: [],
        userNonPins: [],
        globalNonPins: []
    });

    useEffect(() => {
        if (!open) {
            return;
        }

        const activeUserId = store.getState().app.activeUserInfo?.id;

        // Order by personal && pinned, then global && globally pinned, then personal, then global
        const personaOrder = Object.values(store.getState().personas.personas)
            .filter((persona: PersonaData) => {
                return ((persona.type === PersonaType.User && persona.creatorUserId === activeUserId) ||
                    persona.type === PersonaType.Global);
            })
            .sort((a: PersonaData, b: PersonaData) => {
            // Define the priority for sorting
            const priority = (persona: PersonaData) => {
                if (persona.type === PersonaType.User && persona.isPinned) return 1;
                if (persona.type === PersonaType.Global && persona.isGloballyPinned) return 2;
                if (persona.type === PersonaType.User) return 3;
                return 4; // Persona is global and not pinned
            };
            
            // Order by priority
            const priorityDifference = priority(a) - priority(b);
            if (priorityDifference !== 0) {
                return priorityDifference;
            }

            // If both have the same priority, order by name
            return a.name.localeCompare(b.name);
        }).slice(0, shownPersonaLimit);

        const personaOptions: PersonaDataSubMenuOptions = {
            userPins: personaOrder.filter((persona: PersonaData) => persona.type === PersonaType.User && persona.isPinned),
            globalPins: personaOrder.filter((persona: PersonaData) => persona.type === PersonaType.Global && persona.isGloballyPinned),
            userNonPins: personaOrder.filter((persona: PersonaData) => persona.type === PersonaType.User && !persona.isPinned),
            globalNonPins: personaOrder.filter((persona: PersonaData) => persona.type === PersonaType.Global && !persona.isGloballyPinned),
        };
        setShownPersonas(personaOptions);
    }, [open]);

    const createNewChatWithPersona = (personaId: string) => {
        setIsEdit(false);
        void chat.createChat(personaId, projectId);
        dispatch(openProjectFolder(projectId));
        dispatch(setActiveRightHandPanel(RightHandPanel.Chat));

        if (isConversationPaneFill) {
            closeConversationPanel();
        }
    };

    return (
        <>
            <Menu
                open={open}
                onOpenChange={(_event, data) => {
                    setOpen(data.open);
                }}
            >
                <MenuTrigger>
                    <MenuItem>New chat with persona</MenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {shownPersonas.userPins.length > 0 &&
                            <MenuGroup>
                                <MenuGroupHeader>Personal Pins</MenuGroupHeader>
                                {shownPersonas.userPins.map((persona) => (
                                    <MenuItem
                                        key={persona.id}
                                        onClick={() => {
                                            createNewChatWithPersona(persona.id);
                                        }}
                                        icon={getIconByNameNoColorOrBackground(persona.iconName ? persona.iconName : "PersonRegular")}
                                    >
                                        {persona.name}
                                    </MenuItem>
                                ))}
                            </MenuGroup>
                        }
                        {shownPersonas.globalPins.length > 0 &&
                            <>
                                {shownPersonas.userPins.length > 0 &&
                                    <MenuDivider />
                                }
                                <MenuGroup>
                                    <MenuGroupHeader>Global Pins</MenuGroupHeader>
                                        {shownPersonas.globalPins.map((persona) => (
                                        <MenuItem
                                            key={persona.id}
                                            onClick={() => {
                                                createNewChatWithPersona(persona.id);
                                            }}
                                            icon={getIconByNameNoColorOrBackground(persona.iconName ? persona.iconName : "PersonRegular")}
                                        >
                                            {persona.name}
                                        </MenuItem>
                                    ))}
                                </MenuGroup>
                            </>
                        }
                        {shownPersonas.userNonPins.length > 0 &&
                            <>
                                {(shownPersonas.userPins.length > 0 || shownPersonas.globalPins.length > 0) &&
                                        <MenuDivider />
                                }
                                <MenuGroup>
                                    <MenuGroupHeader>Personal</MenuGroupHeader>
                                    {shownPersonas.userNonPins.map((persona) => (
                                        <MenuItem
                                            key={persona.id}
                                            onClick={() => {
                                                createNewChatWithPersona(persona.id);
                                            }}
                                            icon={getIconByNameNoColorOrBackground(persona.iconName ? persona.iconName : "PersonRegular")}
                                        >
                                            {persona.name}
                                        </MenuItem>
                                    ))}
                                </MenuGroup>
                            </>
                        }
                        {shownPersonas.globalNonPins.length > 0 &&
                            <>
                                {(shownPersonas.userPins.length > 0 || shownPersonas.globalPins.length > 0
                                    || shownPersonas.userNonPins.length > 0
                                ) &&
                                    <MenuDivider />
                                }
                                <MenuGroup>
                                    <MenuGroupHeader>Global</MenuGroupHeader>
                                    {shownPersonas.globalNonPins.map((persona) => (
                                        <MenuItem
                                            key={persona.id}
                                            onClick={() => {
                                                createNewChatWithPersona(persona.id);
                                            }}
                                            icon={getIconByNameNoColorOrBackground(persona.iconName ? persona.iconName : "PersonRegular")}
                                        >
                                            {persona.name}
                                        </MenuItem>
                                    ))}
                                </MenuGroup>
                            </>
                        }
                        {(shownPersonas.userPins.length > 0 || shownPersonas.globalPins.length > 0
                            || shownPersonas.userNonPins.length > 0 || shownPersonas.globalNonPins.length > 0
                        ) &&
                            <>
                                <MenuDivider />
                                <MenuGroup>
                                    <MenuItem
                                        onClick={() => {
                                            projectPersonaSelectionDialogRef.current?.openDialog();
                                        }}
                                    >
                                        Other persona...
                                    </MenuItem>
                                </MenuGroup>
                            </>
                        }
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};
