import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { store } from '../../../redux/app/store';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
    },
    header: {
        marginTop: 0,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
    },
    hide: {
        display: 'none',
    },
});

interface IChatListSectionProps {
    header?: string;
    keys: string[];
    isCollapsed: boolean;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({
    header,
    keys,
    isCollapsed,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit,
}) => {
    const classes = useClasses();
    const allKeysInStore = Object.keys(store.getState().conversations.conversations);
    const filteredKeys = keys.filter((key) => allKeysInStore.includes(key));
    return filteredKeys.length > 0 ? (
        <div className={classes.root}>
            <Text className={`${!isCollapsed ? classes.header : classes.hide}`}>{header}</Text>
            {filteredKeys.map((id) => {
                return (
                    <ChatListItem
                        id={id}
                        key={id}
                        isCollapsed={isCollapsed}
                        isConversationPaneFill={isConversationPaneFill}
                        closeConversationPanel={closeConversationPanel}
                        setIsEdit={setIsEdit}
                    />
                );
            })}
        </div>
    ) : null;
};
