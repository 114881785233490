import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger
} from '@fluentui/react-components';
import { EditRegular } from '@fluentui/react-icons';
import React from 'react';
import { DeleteProjectDialog, DeleteProjectDialogRef } from './DeleteProjectDialog';
import { DeleteProjectOption } from './DeleteProjectOption';
import { EditProjectDialog, EditProjectDialogRef } from './EditProjectDialog';
import { NewProjectWithPersonaSubMenu } from './NewProjectWithPersonaSubMenu';
import { ProjectDeletionSubMenu } from './ProjectDeletionSubMenu';
import { ProjectPersonaSelectionDialog, ProjectPersonaSelectionDialogRef } from './ProjectPersonaSelectionDialog';
import { ProjectSelectionDialog, ProjectSelectionDialogRef } from './ProjectSelectionDialog';

interface IProjectOptionsMenuProps {
    projectId: string;
    rightClickToOpen?: boolean;
    TriggerComponent: React.ReactElement;
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
    setIsEdit: (isEdit: boolean) => void;
}

export const ProjectOptionsMenu: React.FC<IProjectOptionsMenuProps> = ({
    projectId,
    rightClickToOpen,
    TriggerComponent,
    isConversationPaneFill,
    closeConversationPanel,
    setIsEdit
}) => {
    const editProjectDialogRef = React.createRef<EditProjectDialogRef>();
    const deleteProjectDialogRef = React.createRef<DeleteProjectDialogRef>();
    const projectPersonaSelectionDialogRef = React.createRef<ProjectPersonaSelectionDialogRef>();
    const projectSelectionDialogRef = React.createRef<ProjectSelectionDialogRef>();

    const deleteAndMoveText = 'This action will permanently delete the project and permanently move all conversations in this project to the new one.';
    const deleteProjectAndMoveChats = (newProjectId: string) => {
        deleteProjectDialogRef.current?.openDialog(deleteAndMoveText, DeleteProjectOption.DeleteAndMoveChats, newProjectId);
    };

    return (
        <>
            <Menu openOnContext={rightClickToOpen === true}>
                <MenuTrigger>{TriggerComponent}</MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <NewProjectWithPersonaSubMenu
                            projectId={projectId}
                            projectPersonaSelectionDialogRef={projectPersonaSelectionDialogRef}
                            isConversationPaneFill={isConversationPaneFill}
                            closeConversationPanel={closeConversationPanel}
                            setIsEdit={setIsEdit}
                        />
                        <MenuItem
                            icon={<EditRegular />}
                            onClick={() => {
                                editProjectDialogRef.current?.openDialog();
                            }}
                        >
                            Edit
                        </MenuItem>
                        {/* <MenuItem
                            icon={<DeleteRegular />}
                            onClick={() => {
                                deleteProjectDialogRef.current?.openDialog();
                            }}
                        >
                            Delete
                        </MenuItem> */}
                        <ProjectDeletionSubMenu
                            projectId={projectId}
                            projectSelectionDialogRef={projectSelectionDialogRef}
                            projectSelectionAction={deleteProjectAndMoveChats}
                            deleteProjectDialogRef={deleteProjectDialogRef}
                        />
                    </MenuList>
                </MenuPopover>
            </Menu>
            <EditProjectDialog projectId={projectId} ref={editProjectDialogRef} />
            <DeleteProjectDialog projectId={projectId} ref={deleteProjectDialogRef} />
            <ProjectPersonaSelectionDialog
                projectId={projectId}
                ref={projectPersonaSelectionDialogRef}
                isConversationPaneFill={isConversationPaneFill}
                closeConversationPanel={closeConversationPanel}
                setIsEdit={setIsEdit}
            />
            <ProjectSelectionDialog
                currentProjectId={projectId}
                selectionAction={deleteProjectAndMoveChats}
                ref={projectSelectionDialogRef}
            />
        </>
    );
};
