import { Card, CardHeader, Text, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

const useClasses = makeStyles({
    card: {
        width: '250px',
        height: '72px',
        maxWidth: '100%',
        padding: '16px',
        gap: 0,
        justifyContent: 'center',
    },
    cardHeader: {
        '& div': {
            alignItems: 'center',
        },
    },
    cardHeaderText: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
});

interface IProjectCardClickableProps {
    projectId: string;
    projectOnClick: () => void;
}

export const ProjectCardClickable: React.FC<IProjectCardClickableProps> = ({ projectId, projectOnClick }) => {
    const classes = useClasses();

    const projectName = useAppSelector((state: RootState) => state.projects.projects[projectId].name);

    return (
        <>
            <Card
                className={classes.card}
                onClick={projectOnClick}
            >
                <CardHeader
                    className={classes.cardHeader}
                    header={
                        <Text weight="semibold" className={classes.cardHeaderText}>
                            {projectName}
                        </Text>
                    }
                />
            </Card>
        </>
    );
};
