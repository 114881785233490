import { makeStyles, SelectTabData, SelectTabEvent, Tab, TabList, TabValue, Textarea, tokens } from "@fluentui/react-components";
import { useState } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ChatMessageDocument } from "../../../libs/models/ChatMessage";
import { SingleDocumentData } from "./ChatHistoryTextContent";

const useClasses = makeStyles({
    codePreview: {
        height: '400px',
        maxHeight: '50vh',
        overflowY: 'scroll',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground3,
        },
    },
    codeExecutionResultContainer: {
        paddingTop: '.5em',
        paddingBottom: '.5em',
    },
    codeExecutionResultText: {
        width: '100%',
        height: '100%',
    },
    codeExecutionResultTextArea: {
        width: '100%',
        height: '445px',
        maxHeight: '50vh',
        overflowY: 'scroll',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground3,
        },
    }
});

interface CodeHistoryDisplayProps {
    documentData: SingleDocumentData;
    chatMessageDocument: ChatMessageDocument;
}

export const CodeHistoryDisplay: React.FC<CodeHistoryDisplayProps> = ({
        documentData,
        chatMessageDocument
    }) => {
    const classes = useClasses();

    const [selectedValue, setSelectedValue] = useState<TabValue>("code");

    const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    return (
        <>
            <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab value="code">
                    Code
                </Tab>
                {chatMessageDocument.codeIntResult && (
                    <Tab value="result">
                        Result
                    </Tab>
                )}
                {chatMessageDocument.codeIntStdOut && (
                    <Tab value="stdout">
                        Output
                    </Tab>
                )}
                {chatMessageDocument.codeIntStdErr && (
                    <Tab value="stderr">
                        Error
                    </Tab>
                )}
            </TabList>
            {selectedValue === "code" && (
                <SyntaxHighlighter
                    style={materialDark}
                    language="python"
                    PreTag="div"
                    wrapLongLines={true}
                    className={classes.codePreview}
                >
                    {documentData.readableDocumentData}
                </SyntaxHighlighter>
            )}
                {selectedValue === "result" && (
                    <div className={classes.codeExecutionResultContainer}>
                        <Textarea
                            className={classes.codeExecutionResultText}
                            resize="vertical"
                            disabled={true}
                            value={chatMessageDocument.codeIntResult}
                            textarea={{ className: classes.codeExecutionResultTextArea }}
                        />
                    </div>
                )}
            {selectedValue === "stdout" && (
                <div className={classes.codeExecutionResultContainer}>
                    <Textarea
                        className={classes.codeExecutionResultText}
                        resize="vertical"
                        disabled={true}
                        value={chatMessageDocument.codeIntStdOut}
                        textarea={{ className: classes.codeExecutionResultTextArea }}
                    />
                </div>
            )}
            {selectedValue === "stderr" && (
                <div className={classes.codeExecutionResultContainer}>
                    <Textarea
                        className={classes.codeExecutionResultText}
                        resize="vertical"
                        disabled={true}
                        value={chatMessageDocument.codeIntStdErr}
                        textarea={{ className: classes.codeExecutionResultTextArea }}
                    />
                </div>
            )}
        </>
    )
};