import { IEditPersonaData, IPersona, IPersonaPluginData } from '../models/Persona';
import { BaseService } from './BaseService';

export class PersonaService extends BaseService {
    // public getPinnedPersonasAsync = async (accessToken: string): Promise<IPersona[]> => {
    //     const result = await this.getResponseAsync<IPersona[]>(
    //         {
    //             commandPath: 'personas/pinned',
    //             method: 'GET',
    //         },
    //         accessToken,
    //     );
    //     return result;
    // };

    public getPersonasAsync = async (
        accessToken: string,
    ): Promise<IPersona[]> => {
        const result = await this.getResponseAsync<IPersona[]>(
            {
                commandPath: 'personas',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };

    public getPersonaAsync = async (personaId: string, chatId: string, accessToken: string): Promise<IPersona> => {
        const result = await this.getResponseAsync<IPersona>(
            {
                commandPath: `personas/${personaId}?chatId=${chatId}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getPersonaLinkedChatCountAsync = async (personaId: string, accessToken: string): Promise<number> => {
        const result = await this.getResponseAsync<number>(
            {
                commandPath: `personas/${personaId}/linkedChatCount`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public createPersonaAsync = async (
        name: string,
        description: string | undefined,
        instructions: string,
        iconName: string | undefined,
        autoChooseIcon: boolean,
        availableIconNames: string[],
        pluginNames: string[],
        iconBackgroundColor: string,
        documents: File[],
        defaultModelType: number | undefined,
        accessToken: string,
    ): Promise<IPersona> => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description ?? '');
        formData.append('instructions', instructions);
        formData.append('iconName', iconName ?? '');
        formData.append('autoChooseIcon', autoChooseIcon.toString());
        availableIconNames.forEach((iconName, index) => {
            formData.append(`availableIconNames[${index}]`, iconName);
        });
        pluginNames.forEach((pluginName, index) => {
            formData.append(`pluginNames[${index}]`, pluginName);
        });
        formData.append('iconBackgroundColor', iconBackgroundColor);

        //Only add documents if BingSearch is not a plugin
        //if (pluginNames.filter((pluginName) => pluginName === 'BingSearch').length == 0) {
        for (const document of documents) {
            formData.append('documentsToAdd', document);
        }
        //}

        formData.append('defaultModelType', defaultModelType?.toString() ?? '');

        const result = await this.getResponseAsync<IPersona>(
            {
                commandPath: 'personas',
                method: 'POST',
                body: formData,
            },
            accessToken,
        );

        return result;
    };

    public editPersonaAsync = async (
        personaId: string,
        name: string,
        description: string | undefined,
        instructions: string,
        iconName: string | undefined,
        autoChooseIcon: boolean,
        availableIconNames: string[],
        pluginNames: string[],
        iconBackgroundColor: string,
        documents: File[],
        documentsToDelete: string[],
        defaultModelType: number | undefined,
        accessToken: string,
    ): Promise<IEditPersonaData> => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description ?? '');
        formData.append('instructions', instructions);
        formData.append('iconName', iconName ?? '');
        formData.append('autoChooseIcon', autoChooseIcon.toString());
        availableIconNames.forEach((iconName, index) => {
            formData.append(`availableIconNames[${index}]`, iconName);
        });
        pluginNames.forEach((pluginName, index) => {
            formData.append(`pluginNames[${index}]`, pluginName);
        });
        formData.append('iconBackgroundColor', iconBackgroundColor);
        for (const document of documents) {
            formData.append('documentsToAdd', document);
        }
        documentsToDelete.forEach((documentId, index) => {
            formData.append(`documentsToRemove[${index}]`, documentId);
        });

        formData.append('defaultModelType', defaultModelType?.toString() ?? '');

        const result = await this.getResponseAsync<IEditPersonaData>(
            {
                commandPath: `personas/${personaId}`,
                method: 'PATCH',
                body: formData,
            },
            accessToken,
        );

        return result;
    };

    public convertToGlobalAsync = async (personaId: string, accessToken: string): Promise<object> => {
        const result = await this.getResponseAsync<object>(
            {
                commandPath: `personas/${personaId}/convertToGlobal`,
                method: 'PATCH',
            },
            accessToken,
        );

        return result;
    };

    public deletePersonaAsync = async (personaId: string, accessToken: string): Promise<object> => {
        const result = await this.getResponseAsync<object>(
            {
                commandPath: `personas/${personaId}`,
                method: 'DELETE',
            },
            accessToken,
        );

        return result;
    };

    public togglePersonaPinAsync = async (
        personaId: string,
        newPinSetting: boolean,
        accessToken: string,
    ): Promise<object> => {
        const result = await this.getResponseAsync<object>(
            {
                commandPath: `personas/${personaId}/pin?newPinSetting=${newPinSetting}`,
                method: 'PATCH',
            },
            accessToken,
        );

        return result;
    };

    public toggleGlobalPersonaPinAsync = async (
        personaId: string,
        newPinSetting: boolean,
        accessToken: string,
    ): Promise<object> => {
        const result = await this.getResponseAsync<object>(
            {
                commandPath: `personas/${personaId}/globallyPin?newPinSetting=${newPinSetting}`,
                method: 'PATCH',
            },
            accessToken,
        );

        return result;
    };

    public getPersonaPluginsAsync = async (accessToken: string): Promise<IPersonaPluginData[]> => {
        const result = await this.getResponseAsync<IPersonaPluginData[]>(
            {
                commandPath: 'personas/plugins',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };
}
