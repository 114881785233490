import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, makeStyles, mergeClasses, shorthands, Spinner, tokens } from '@fluentui/react-components';
import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { useProjects } from '../../../libs/hooks';
import { store } from '../../../redux/app/store';
import { SharedStyles } from '../../../styles';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    error: {
        color: '#d13438',
    },
    actions: {
        paddingTop: '10%',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
});

interface IEditProjectDialogProps {
    projectId: string;
}

export interface EditProjectDialogRef {
    openDialog: () => void;
}

export const EditProjectDialog = forwardRef<EditProjectDialogRef, IEditProjectDialogProps>(({ projectId }, ref) => {
    const projects = useProjects();
    const classes = useClasses();

    const [projectName, setProjectName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        void projects.editProject(projectId, projectName)
            .then(() => {
                setOpen(false);
                setErrorMessage('');
            })
            .catch(() => {
                setErrorMessage('Failed to edit project. Please try again later.');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load project data from redux store
            setProjectName(store.getState().projects.projects[projectId].name);

            setOpen(true);
        },
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Project Folder:</DialogTitle>
                        <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Project Name">
                                <Input
                                    required
                                    type="text"
                                    value={projectName}
                                    onChange={(_e, input) => {
                                        setProjectName(input.value);
                                    }}
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
});

EditProjectDialog.displayName = 'EditProjectDialog';