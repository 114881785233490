import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, Spinner } from '@fluentui/react-components';
import { FormEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { useChat, useProjects } from '../../../libs/hooks';
import { sortConversationsIds } from '../../../libs/hooks/useChat';
import { AlertType } from '../../../libs/models/AlertType';
import { useAppDispatch } from '../../../redux/app/hooks';
import { store } from '../../../redux/app/store';
import { addAlert } from '../../../redux/features/app/appSlice';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { openProjectFolder } from '../../../redux/features/projects/projectSlice';
import { DeleteProjectOption } from './DeleteProjectOption';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    error: {
        color: '#d13438',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IDeleteProjectDialogProps {
    projectId: string;
}

export interface DeleteProjectDialogRef {
    openDialog: (details: string, projectDeletionOption: DeleteProjectOption, newProjectId?: string) => void;
}

export const DeleteProjectDialog = forwardRef<DeleteProjectDialogRef, IDeleteProjectDialogProps>(({ projectId }, ref) => {
    const chat = useChat();
    const projects = useProjects();
    const dispatch = useAppDispatch();
    const classes = useClasses();

    const [projectName, setProjectName] = useState('');
    const [details, setDetails] = useState('');
    const [newProjectId, setNewProjectId] = useState('');
    const [projectDeletionOption, setProjectDeletionOption] = useState(DeleteProjectOption.DeleteAndRetainChats);
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // Delete project and retain chats via removing reference to project (default behavior)
    const deleteAndRetainChats = async () => projects.deleteProject(projectId);

    // Delete project and delete or leave chats based on owner of chat
    const deleteAndDeleteLeaveChats = async () => {
        const userId = store.getState().app.activeUserInfo?.id;
        const allConvos = Object.values(store.getState().conversations.conversations);
        const convosInProject = allConvos
            .filter((conversation) => conversation.projectId === projectId);

        // If there won't be any convos left, create a new conversation and set as active chat
        if (convosInProject.length === allConvos.length) {
            const newChatId = await chat.createChat();
            if (newChatId) {
                dispatch(setSelectedConversation(newChatId));
            }
        }

        // If the currently selected convo is in the project, set it to the latest chat
        const selectedId = store.getState().conversations.selectedId;
        if (selectedId && convosInProject.some((conversation) => conversation.id === selectedId)) {
            const nonProjectChats = allConvos
                .filter((conversation) => !conversation.projectId);
            const nonProjectChatsConversations: Conversations = {};
            for (const conversation of nonProjectChats) {
                nonProjectChatsConversations[conversation.id] = conversation;
            }
            const sortedIds = sortConversationsIds(nonProjectChatsConversations);
            if (sortedIds.length > 0) {
                const latestChat = nonProjectChatsConversations[sortedIds[0]];
                dispatch(setSelectedConversation(latestChat.id));
            }
        }
        
        const tasks = convosInProject.map((conversation) => {
            if (conversation.creatorUserId === userId) {
                return chat.deleteChat(conversation.id, false);
            } else {
                return chat.leaveChat(conversation.id, false);
            }
        });

        const results = await Promise.all(tasks);
        const allSuccess = results.every(result => result.success);

        if (allSuccess) {
            await projects.deleteProject(projectId);
        }
    };

    // Delete project and move chats to new project
    const deleteAndMoveChats = async () => {
        const convosInProject = Object.values(store.getState().conversations.conversations)
            .filter((conversation) => conversation.projectId === projectId);

        const tasks = convosInProject.map((conversation) => {
            return chat.setChatSessionProject(conversation.id, newProjectId);
        });

        const results = await Promise.all(tasks);
        const allSuccess = results.every(result => result.success);

        if (allSuccess) {
            await projects.deleteProject(projectId);
        }

        dispatch(openProjectFolder(newProjectId));
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        // Set deletion function based off of projectDeletionOption
        let deleteChat: () => Promise<void>;
        switch (projectDeletionOption) {
            case DeleteProjectOption.DeleteAndRetainChats:
                deleteChat = deleteAndRetainChats;
                break;
            case DeleteProjectOption.DeleteAndDeleteLeaveChats:
                deleteChat = deleteAndDeleteLeaveChats;
                break;
            case DeleteProjectOption.DeleteAndMoveChats:
                deleteChat = deleteAndMoveChats;
                break;
            default:
                throw new Error('Invalid project deletion option');
        }

        void deleteChat()
            .then(() => {
                setOpen(false);
            })
            .catch(() => {
                dispatch(addAlert({ message: 'Failed to delete project. Please try again later.', type: AlertType.Error }));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: (details: string, projectDeletionOption: DeleteProjectOption, newProjectId?: string) => {
            // Load project data from redux store
            setProjectName(store.getState().projects.projects[projectId].name);
            setDetails(details);
            setProjectDeletionOption(projectDeletionOption);
            setNewProjectId(newProjectId ?? '');
            setOpen(true);
        },
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Are you sure you want to delete project: {projectName}?</DialogTitle>
                        <DialogContent>
                            {details}
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
});

DeleteProjectDialog.displayName = 'DeleteProjectDialog';