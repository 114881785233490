import { Body1, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Field, Input, Label, makeStyles, mergeClasses, shorthands, Spinner, tokens } from '@fluentui/react-components';
import { FC, FormEvent, useState } from 'react';
import { useProjects } from '../../../libs/hooks';
import { SharedStyles } from '../../../styles';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    error: {
        color: '#d13438',
    },
    actions: {
        paddingTop: '10%',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    deleteNoticeDiv: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    deleteNotice: {
        fontSize: '12px',
        fontStyle: 'italic',
        marginTop: '10px',
    },
});

interface INewProjectProps {
    TriggerComponent: React.ReactElement;
}

export const NewProject: FC<INewProjectProps> = ({ TriggerComponent }) => {
    const projects = useProjects();
    const classes = useClasses();

    const [projectName, setProjectName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);

        void projects.createProject(projectName)
            .then(() => {
                setOpen(false);
                setErrorMessage('');
                setProjectName('');
            })
            .catch(() => {
                setErrorMessage('Failed to create project. Please try again later.');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger>{TriggerComponent}</DialogTrigger>
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Create A New Project Folder:</DialogTitle>
                        <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Project Name">
                                <Input
                                    required
                                    type="text"
                                    value={projectName}
                                    onChange={(_e, input) => {
                                        setProjectName(input.value);
                                    }}
                                />
                            </Field>
                            <div className={classes.deleteNoticeDiv}>
                                <Label className={classes.deleteNotice}>
                                    Created project folders will be deleted if not used within the past year.
                                </Label>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};
