import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { removeProjectAssociations } from '../../redux/features/conversations/conversationsSlice';
import {
    addProject as addProjectRedux,
    deleteProject as deleteProjectRedux,
    editProject as editProjectRedux,
    setProjects as setProjectsRedux,
} from '../../redux/features/projects/projectSlice';
import { ProjectData, Projects } from '../../redux/features/projects/ProjectState';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { IProject } from '../models/Project';
import { ProjectService } from '../services/ProjectService';

export const useProjects = () => {
    const dispatch = useAppDispatch();
    const { instance, inProgress } = useMsal();
    const projectService = new ProjectService();

    const loadProjects = async () => {
        try {
            await projectService
                .getProjectsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then((result: IProject[]) => {
                    const projects: Projects = {};
                    result.forEach((project) => {
                        projects[project.id] = {
                            id: project.id,
                            name: project.name,
                            userId: project.userId,
                            lastUpdatedDate: new Date(project.lastUpdatedDate).getTime(),
                            lastUsedDate: new Date(project.lastUsedDate).getTime(),
                            folderOpen: false,
                        };
                    });

                    dispatch(setProjectsRedux(projects));
                });
        } catch (e: any) {
            const errorMessage = 'Unable to load projects.';
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const createProject = async (name: string) => {
        try {
            await projectService
                .createProjectAsync(name, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then((result: IProject) => {
                    const newProject: ProjectData = {
                        id: result.id,
                        name: result.name,
                        userId: result.userId,
                        lastUpdatedDate: new Date(result.lastUpdatedDate).getTime(),
                        lastUsedDate: new Date(result.lastUsedDate).getTime(),
                        folderOpen: true,
                    };

                    dispatch(addProjectRedux(newProject));
                    return newProject.id;
                });
        } catch (e: any) {
            const errorMessage = 'Unable to create new project.';
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const editProject = async (projectId: string, name: string) => {
        try {
            await projectService
                .editProjectAsync(projectId, name, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then((result: IProject) => {
                    dispatch(
                        editProjectRedux({
                            id: result.id,
                            name: result.name,
                            userId: result.userId,
                            lastUpdatedDate: new Date(result.lastUpdatedDate).getTime(),
                            lastUsedDate: new Date(result.lastUsedDate).getTime(),
                            folderOpen: false,
                        }),
                    );
                    
                    return result.id;
                });
        } catch (e: any) {
            const errorMessage = 'Unable to update project.';
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    const deleteProject = async (projectId: string) => {
        try {
            await projectService
                .deleteProjectAsync(projectId, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
                .then(() => {
                    dispatch(removeProjectAssociations(projectId));
                    dispatch(deleteProjectRedux(projectId));
                });
        } catch (e: any) {
            const errorMessage = 'Unable to delete project.';
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        }
    };

    return {
        loadProjects,
        createProject,
        editProject,
        deleteProject,
    };
};
