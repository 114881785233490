import { Button } from '@fluentui/react-button';
import { Body1, Field, Input, Select, Spinner, Switch, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, Textarea, Tooltip, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { ArrowUndoRegular, DeleteRegular, DocumentArrowUp20Regular } from '@fluentui/react-icons';
import { FormEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Constants } from '../../../../Constants';
import { usePersonas } from '../../../../libs/hooks/usePersonas';
import { IPersonaDocument } from '../../../../libs/models/Persona';
import { store } from '../../../../redux/app/store';
import { SharedStyles } from '../../../../styles';
import { FluentIconPicker } from '../../../icon-picker/FluentIconPicker';
import { anyFilesCodeInterpreterOnlyFiles, getReadableByteString } from '../../../utils/FileUtils';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    actions: {
        paddingTop: '10%',
    },
    createButton: {
        marginTop: tokens.spacingVerticalXL,
        marginBottom: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        width: 'fit-content',
    },
    textareaInput: {
        height: '100px',
    },
    error: {
        color: '#d13438',
    },
    iconQuestions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    uploadButton: {
        ...shorthands.margin('0', tokens.spacingHorizontalS, '0', '0'),
    },
    documentTableCell: {
        overflow: 'hidden',
    },
    documentDeletedRow: {
        backgroundColor: tokens.colorStatusDangerBackground2,
        '&:hover': {
            backgroundColor: tokens.colorStatusDangerBackground2,
        },
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    warning: {
        color: '#d13438',
        fontWeight: 'bold',
    },
});

interface IEditPersonaDialogProps {
    personaId: string;
}

interface PersonaDocumentTableDetails {
    id: string; // Document ID if this is an already uploaded document
    fileName: string;
    size: string;
    status: string;
}

export interface EditPersonaDialogRef {
    openDialog: () => void;
}

export const EditPersonaDialog = forwardRef<EditPersonaDialogRef, IEditPersonaDialogProps>(({ personaId }, ref) => {
    const personaNameRef = useRef<HTMLInputElement>(null);
    const classes = useClasses();
    const personas = usePersonas();

    const modelOptions = [
        { value: 0, label: 'Balanced' },
        { value: 1, label: 'Faster' },
        { value: 2, label: 'Better (coming soon)' },
    ];

    const [personaName, setPersonaName] = useState('');
    const [personaDescription, setPersonaDescription] = useState('');
    const [personaInstructions, setPersonaInstructions] = useState('');
    const [personaDefaultModelType, setPersonaDefaultModelType] = useState(modelOptions[1]);
    const [personaDefaultModelTypeSwitch, setPersonaDefaultModelTypeSwitch] = useState(false);
    const [personaIcon, setPersonaIcon] = useState<string | undefined>('');
    const [chooseIcon, setChooseIcon] = useState(false);
    const [personaIconBackgroundColor, setPersonaIconBackgroundColor] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const personaDocumentFileRef = useRef<HTMLInputElement | null>(null);
    const [personaDocuments, setPersonaDocuments] = useState<IPersonaDocument[]>([]);
    const [combinedDocuments, setCombinedDocuments] = useState<PersonaDocumentTableDetails[]>([]);
    const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);

    const [personaPlugins] = useState(store.getState().personas.personaPlugins);
    const [pluginSwitches, setPluginSwitches] = useState(() => {
        const pluginSwitchesInitial: Record<string, boolean> = {};
        return pluginSwitchesInitial;
    });

    const handleSwitchChange = (plugin: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setPluginSwitches({
            ...pluginSwitches,
            [plugin]: event.target.checked,
        });
    };

    const iconChangePerPicker = (iconName: string, iconBackgroundColor: string) => {
        setPersonaIcon(iconName);
        setPersonaIconBackgroundColor(iconBackgroundColor);
    };

    useEffect(() => {
        const newCombinedDocuments: PersonaDocumentTableDetails[] = [];
        for (const document of personaDocuments) {
            newCombinedDocuments.push({
                id: document.id,
                fileName: document.fileName,
                size: document.size,
                status: document.isUploaded ? "Uploaded" : "Upload not complete",
            });
        }
        for (const document of uploadedFiles) {
            newCombinedDocuments.push({
                id: "",
                fileName: document.name,
                size: getReadableByteString(document.size),
                status: "Pending Upload",
            });
        }
        setCombinedDocuments(newCombinedDocuments);
    }, [personaDocuments, uploadedFiles]);

    const [acceptedFileTypes, setAcceptedFileTypes] = useState<string>(Constants.app.importTypes);
    useEffect(() => { 
        if (pluginSwitches.PythonCodeInterpreter) {
            setAcceptedFileTypes(Constants.app.importTypes + ',' + Constants.app.importTypesCodeInterpreterOnly);
        } else {
            setAcceptedFileTypes(Constants.app.importTypes);
        }
    }, [pluginSwitches.PythonCodeInterpreter]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (submitting) {
            return;
        }
        setSubmitting(true);
        const personaPluginsSelected = Object.keys(pluginSwitches).filter((plugin) => pluginSwitches[plugin]);
        void personas
            .editPersona(
                personaId,
                personaName,
                personaDescription,
                personaInstructions,
                personaIcon,
                chooseIcon,
                personaPluginsSelected,
                personaIconBackgroundColor,
                uploadedFiles,
                documentsToDelete,
                personaDefaultModelTypeSwitch ? personaDefaultModelType.value : undefined,
            )
            .then(() => {
                setOpen(false);
                setErrorMessage('');
            })
            .catch(() => {
                setErrorMessage('Failed to create persona. Please try again later.');
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load persona data from redux store
            setPersonaName(store.getState().personas.personas[personaId].name);
            setPersonaDescription(store.getState().personas.personas[personaId].description ?? '');
            setPersonaInstructions(store.getState().personas.personas[personaId].instructions);
            const defaultModelType = store.getState().personas.personas[personaId].defaultModelType;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            setPersonaDefaultModelTypeSwitch(defaultModelType !== null);
            setPersonaDefaultModelType(modelOptions[defaultModelType ?? 1]);
            setPersonaIcon(store.getState().personas.personas[personaId].iconName);
            setPersonaIconBackgroundColor(store.getState().personas.personas[personaId].iconBackgroundColor);
            setUploadedFiles([]);
            setPersonaDocuments(store.getState().personas.personas[personaId].documents);
            setDocumentsToDelete([]);

            // Set plugin switches
            setPluginSwitches(() => {
                const pluginSwitchesInitial: Record<string, boolean> = {};
                const currentlyEnabledPlugins = store.getState().personas.personas[personaId].pluginNames;
                for (const plugin of personaPlugins) {
                    pluginSwitchesInitial[plugin.pluginName] = currentlyEnabledPlugins.includes(plugin.pluginName);
                }
                return pluginSwitchesInitial;
            });

            setOpen(true);
        },
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Persona:</DialogTitle>
                        <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                            {errorMessage && <Body1 className={classes.error}>{errorMessage}</Body1>}
                            <Field label="Persona Name (Reference Only)">
                                <Input
                                    required
                                    type="text"
                                    value={personaName}
                                    onChange={(_e, input) => {
                                        setPersonaName(input.value);
                                    }}
                                    ref={personaNameRef}
                                />
                            </Field>
                            <Field label="Description">
                                <Textarea
                                    textarea={{ className: classes.textareaInput }}
                                    resize="vertical"
                                    value={personaDescription}
                                    onChange={(_e, input) => {
                                        setPersonaDescription(input.value);
                                    }}
                                    placeholder="Give the persona a description (optional)..."
                                />
                            </Field>
                            <Field label="Instructions">
                                <Textarea
                                    required
                                    textarea={{ className: classes.textareaInput }}
                                    resize="vertical"
                                    value={personaInstructions}
                                    onChange={(_e, input) => {
                                        setPersonaInstructions(input.value);
                                    }}
                                    placeholder="Customize the way the AI responds to you..."
                                />
                            </Field>
                            <Field label="Icon">
                                <div className={classes.iconQuestions}>
                                    <FluentIconPicker
                                        currentIconName={personaIcon}
                                        currentIconBackgroundColor={personaIconBackgroundColor}
                                        iconOnChange={iconChangePerPicker}
                                        focusOnClose={() => {
                                            // Focus on the input field after the icon picker closes
                                            // To fix default focus on background bug
                                            personaNameRef.current?.focus();
                                        }}
                                    />
                                    <Switch
                                        label="Automatically pick an icon if one isn't chosen"
                                        checked={chooseIcon}
                                        onChange={(_, data) => {
                                            setChooseIcon(data.checked);
                                        }}
                                    />
                                </div>
                            </Field>
                            <Field label="Default Model Type">
                                <Switch
                                    label={`Allows for overriding the default model type when users chat with this persona. Users will still have the option to change the model type if they choose.`}
                                    checked={personaDefaultModelTypeSwitch}
                                    onChange={() => {
                                        setPersonaDefaultModelTypeSwitch(!personaDefaultModelTypeSwitch);
                                        setPersonaDefaultModelType(modelOptions[1]);
                                    }}
                                />
                                {personaDefaultModelTypeSwitch && (
                                    <Select
                                        value={personaDefaultModelType.value}
                                        onChange={(event) => {
                                            const selectedOption = modelOptions.find(
                                                (option) => option.value === Number(event.target.value),
                                            );
                                            if (selectedOption) {
                                                setPersonaDefaultModelType(selectedOption);
                                            }
                                        }}
                                    >
                                        {modelOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            </Field>
                            {process.env.REACT_APP_PLUGIN_SELECTION_DISABLED !== 'true' && (
                                <Field label="Plugins">
                                    {personaPlugins.map((plugin) => (
                                        <Switch
                                            key={plugin.pluginName}
                                            label={`${plugin.pluginDisplayName} - ${plugin.pluginDescription}`}
                                            checked={pluginSwitches[plugin.pluginName]}
                                            onChange={handleSwitchChange(plugin.pluginName)}
                                        />
                                    ))}
                                </Field>
                            )}
                            <Field label="Documents">
                                {pluginSwitches.BingSearch && (
                                    <p className={classes.warning}>
                                        Documents are not available when using the Bing Search plugin - any persona
                                        or chat documents associated with any chats using this persona will be permanently deleted.
                                    </p>
                                )}
                                {!pluginSwitches.PythonCodeInterpreter && anyFilesCodeInterpreterOnlyFiles(combinedDocuments
                                        .filter((value, _) => !documentsToDelete.includes(value.id))
                                        .map((file) => file.fileName)) && (
                                    <p className={classes.warning}>
                                        The following file types are only compatible with the Python Code Interpreter 
                                        and will not be uploaded/retained: {Constants.app.importTypesCodeInterpreterOnly.replace(/,/g, ', ')}.
                                    </p>
                                )}
                                <input
                                    type="file"
                                    ref={personaDocumentFileRef}
                                    style={{ display: 'none' }}
                                    accept={acceptedFileTypes}
                                    multiple={true}
                                    onChange={() => {
                                        if (personaDocumentFileRef.current?.files?.length) {
                                            const filesArray = Array.from(personaDocumentFileRef.current.files);
                                            setUploadedFiles(filesArray);
                                        } else {
                                            setUploadedFiles([]);
                                        }
                                    }}
                                />
                                <div>
                                    <Tooltip
                                        content="Add documents to all chats with this persona"
                                        relationship="label"
                                    >
                                        <Button
                                            disabled={pluginSwitches.BingSearch}
                                            className={classes.uploadButton}
                                            icon={<DocumentArrowUp20Regular />}
                                            onClick={() => personaDocumentFileRef.current?.click()}
                                        >
                                            Upload
                                        </Button>
                                    </Tooltip>
                                </div>
                                {combinedDocuments.length > 0 && (
                                    <Table arial-label="Persona documents" size="small">
                                        <TableHeader>
                                            <TableRow>
                                                <TableHeaderCell key="name">Name</TableHeaderCell>
                                                <TableHeaderCell key="status">Status</TableHeaderCell>
                                                <TableHeaderCell key="actions" style={{ width: '50px' }}>
                                                    Actions
                                                </TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {combinedDocuments.map((item) => (
                                                <TableRow
                                                    key={item.fileName}
                                                    className={
                                                        documentsToDelete.includes(item.id)
                                                            ? classes.documentDeletedRow
                                                            : ''
                                                    }
                                                >
                                                    <TableCell className={classes.documentTableCell}>
                                                        <TableCellLayout>{item.fileName}</TableCellLayout>
                                                    </TableCell>
                                                    <TableCell className={classes.documentTableCell}>
                                                        <TableCellLayout>{item.status}</TableCellLayout>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TableCellLayout>
                                                            {item.id !== '' && (
                                                                <>
                                                                    {documentsToDelete.includes(item.id) ? (
                                                                        <Tooltip
                                                                            content="Undo marking to delete"
                                                                            relationship="label"
                                                                        >
                                                                            <Button
                                                                                icon={<ArrowUndoRegular />}
                                                                                aria-label="Undo Delete"
                                                                                onClick={() => {
                                                                                    setDocumentsToDelete(
                                                                                        documentsToDelete.filter(
                                                                                            (id) => id !== item.id,
                                                                                        ),
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip
                                                                            content="Delete document"
                                                                            relationship="label"
                                                                        >
                                                                            <Button
                                                                                icon={<DeleteRegular />}
                                                                                aria-label="Delete"
                                                                                onClick={() => {
                                                                                    setDocumentsToDelete([
                                                                                        ...documentsToDelete,
                                                                                        item.id,
                                                                                    ]);
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </>
                                                            )}
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </Field>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button
                                appearance="primary"
                                type="submit"
                                icon={submitting ? <Spinner size="tiny" /> : undefined}
                                disabledFocusable={submitting}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
});

EditPersonaDialog.displayName = 'EditPersonaDialog';
