
export interface ProjectState {
    projects: Projects;
}

export const initialState: ProjectState = {
    projects: {},
};

export type Projects = Record<string, ProjectData>;

export interface ProjectData {
    id: string;
    name: string;
    userId: string;
    lastUpdatedDate: number;
    lastUsedDate: number;
    folderOpen: boolean;
}
