import { Button } from '@fluentui/react-button';
import { makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogOpenChangeData,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { useAppSelector } from '../../../../redux/app/hooks';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IDeleteChatDialogProps {
    chatId: string;
}

export interface DeleteChatDialogRef {
    openDialog: () => void;
}

export const DeleteChatDialog = forwardRef<DeleteChatDialogRef, IDeleteChatDialogProps>(({ chatId }, ref) => {
    const classes = useClasses();
    const chat = useChat();

    const lastMessageSnippet = useAppSelector((state) => state.conversations.conversations[chatId].lastMessageSnippet);
    const conversationTitle = useAppSelector((state) => state.conversations.conversations[chatId].title);
    const chatName = getFriendlyChatName(lastMessageSnippet, conversationTitle);

    const [open, setOpen] = useState(false);

    const onDeleteChat = () => {
        void chat.deleteChat(chatId)
    };

    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            setOpen(true);
        }
    }));

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_ev: any, data: DialogOpenChangeData) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete chat: {chatName}?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete the chat, and any associated resources and memories, for all
                        participants, including Eastman Copilot.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                onClick={onDeleteChat}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

DeleteChatDialog.displayName = 'DeleteChatDialog';