import { Body1, tokens } from '@fluentui/react-components';
import { IChatMessage } from '../../libs/models/ChatMessage';

/*
 * Function to check if date is today.
 */
export function isToday(date: Date) {
    return date.toDateString() === new Date().toDateString();
}

/*
 * Function to render the date and/or time of a message.
 */
export function timestampToDateString(timestamp: number, alwaysShowTime = false, showSeconds = false) {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString([], {
        month: 'numeric',
        day: 'numeric',
    });
    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
    };
    if (showSeconds) {
        timeOptions.second = '2-digit';
    }
    const timeString = date.toLocaleTimeString([], timeOptions);

    return date.toDateString() !== new Date().toDateString()
        ? alwaysShowTime
            ? dateString + ' ' + timeString // if the date is not today and we are always showing the time, show the date and time
            : dateString // if the date is not today and we are not always showing the time, only show the date
        : timeString; // if the date is today, only show the time
}

/*
 * Function to create a command link
 */
export function createCommandLink(command: string) {
    const escapedCommand = encodeURIComponent(command);
    const createCommandLink = `<span style="text-decoration: underline; cursor: pointer" data-command="${escapedCommand}" onclick="(function(){ let chatInput = document.getElementById('chat-input'); chatInput.value = decodeURIComponent('${escapedCommand}'); chatInput.focus(); return false; })();return false;">${command}</span>`;
    return createCommandLink;
}

/*
 * Function to format chat text content to remove any html tags from it.
 */
export function formatChatTextContent(messageContent: string) {
    const contentAsString = messageContent
        .trim()
        .replace(/^sk:\/\/.*$/gm, (match: string) => createCommandLink(match))
        .replace(/^!sk:.*$/gm, (match: string) => createCommandLink(match));
    const content = detectCodeLanguage(contentAsString);
    return content;
}

/*
 * Formats text containing `\n` or `\r` into paragraphs.
 */
export function formatParagraphTextContent(messageContent = '') {
    messageContent = messageContent.replaceAll('\r\n', '\n\r');

    return (
        <Body1>
            {messageContent.split('\n').map((paragraph, idx) => (
                <p
                    key={`paragraph-${idx}`}
                    style={
                        paragraph.includes('\r')
                            ? {
                                  display: 'flex',
                                  marginLeft: tokens.spacingHorizontalL,
                              }
                            : {
                                  overflowWrap: 'anywhere',
                              }
                    }
                >
                    {paragraph}
                </p>
            ))}
        </Body1>
    );
}

function detectCodeLanguage(content: string): string {    
    let modifiedContent = content;
    const firstIndex = modifiedContent.indexOf("```");

    if (firstIndex != -1) {
        let lastIndex = modifiedContent.indexOf("```", firstIndex + 3);
        if (lastIndex == -1) {
            lastIndex = modifiedContent.length - 1;
        }
        const codeBlock = modifiedContent.substring(modifiedContent.indexOf("\n", firstIndex), lastIndex);
        const codeLanguage = modifiedContent.substring(firstIndex, modifiedContent.indexOf("\n", firstIndex)).trim();
        //code language not identified in response
        if (codeLanguage.length == 3) {            
            let detectedLanguage = "python";
            const languages = {
                python: ["def ", "import ", "from "],
                csharp: ["public ", "private ", "string ", "void ", "class "],
                javascript: ["function ", "var ", "let ", "const "],
                c: ["#include", "int main("],
                r: ["<-", "function(", "library("]            
            };
            for (const [lang, keywords] of Object.entries(languages)) {                
                if (keywords.some(keyword => codeBlock.includes(keyword))) {
                    detectedLanguage = lang;
                    break;
                }
            }
            modifiedContent = modifiedContent.replace(codeLanguage, "```" + detectedLanguage);
        }
    }
    
    return modifiedContent;
}

export function formatTableToCSV(content: string): string[] {
    const formattedContent = formatChatTextContent(content);
    const regex = /(\|.*\|.*)/g;
    let match;
    const records = [];
    const regexHeaderSeparator = /(\|\-*\|\-*\|)/g;
    
    const headerIndexes: number[] = []
    const tables: string[][] = [];
    const tablesCSV: string[] = [];
    while ((match = regex.exec(formattedContent)) !== null) {
        records.push(match[0]);
        if (match[0].match(regexHeaderSeparator)) {
            headerIndexes.push(records.length - 1)
        }
    }
    for (let i = 0; i < headerIndexes.length; i++) {
        if (i + 1 >= headerIndexes.length) {
            tables.push(records.slice(headerIndexes[i] - 1));
        }
        else {
            tables.push(records.slice(headerIndexes[i] - 1, headerIndexes[i + 1] - 1));
        }
    }

    for (const table of tables) {
        let csvOutput = "";
        // Expecting 1 header row, one dash row, and at least one row of values
        if (table.length >= 3) {
            const rows = table.filter(row => row.trim() !== "");

            if (rows.length <= 0) {
                tablesCSV.push("");
                continue;
            }

            const headers = rows[0]
                .trim()
                .slice(1, -1)
                .split("|")
                .map(cell => cell.trim().replace(",", " "));

            //This skips the '---' row immediately after the header row.
            const data = rows.slice(2).map(row =>
                row
                    .trim()
                    .slice(1, -1)
                    .split("|")
                    .map(cell => cell.trim())
            );
            [headers, ...data].forEach(row => {
                for (let columnCount = 0; columnCount < row.length; columnCount++) {
                    if (row[columnCount].includes(",")) {
                        csvOutput += `"${row[columnCount].replaceAll("\"", "\"\"")}"`;
                    }
                    else {
                        csvOutput += `${row[columnCount]}`;
                    }
                    if (columnCount != row.length - 1) {
                        csvOutput += ",";
                    }
                }
                csvOutput += "\r\n";
            });
        }
        tablesCSV.push(csvOutput);
    }
    return tablesCSV;
}
/*
 * Function to replace citation links with indices matching the citation list.
 */
export function replaceCitationLinksWithIndices(formattedMessageContent: string, message: IChatMessage) {
    const citations = message.citations;
    if (citations) {
        citations.forEach((citation, index) => {
            const citationLink = citation.link;
            formattedMessageContent = formattedMessageContent.replaceAll(citationLink, (index + 1).toString());
        });
    }

    return formattedMessageContent;
}

/**
 * Gets message of error
 */
export function getErrorDetails(error: unknown) {
    return error instanceof Error ? error.message : String(error);
}
