import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Text,
    makeStyles
} from "@fluentui/react-components";
import * as React from "react";
import { ModelType } from "./ModelType";

const useStyles = makeStyles({
    contentHeader: {
        marginTop: "0",
    },
    conversationStyleTextLargeView: {
        display: "inline",
        '@media (max-width: 1140px)': {
            display: "none"
        }
    },
    conversationStyleTextSmallView: {
        display: "none",
        '@media (max-width: 1140px)': {
            display: "inline"
        }
    }
});

interface ModelTypeProps {
    modelTypeValue: number;
    setModelTypeValue: React.Dispatch<React.SetStateAction<number>>;
}

export const ModelTypeControl = ({ modelTypeValue, setModelTypeValue }: ModelTypeProps) => {
    const styles = useStyles();
  
    return (
        <div style={{ margin: '5px' }}>
            <Popover withArrow={true}>
                <PopoverTrigger disableButtonEnhancement>
                    <Button>
                        <span
                            className={styles.conversationStyleTextLargeView}
                        >{`Model: ${ModelType[modelTypeValue]}`}</span>
                        <span className={styles.conversationStyleTextSmallView}>{ModelType[modelTypeValue]}</span>
                    </Button>
                </PopoverTrigger>
                <PopoverSurface>
                    <div>
                        <h4 className={styles.contentHeader}>Choose an AI model type:</h4>
                        <RadioGroup
                            value={modelTypeValue.toString()}
                            onChange={(_, data) => {
                                // data.value is a string of the ModelType enum value (ex. 0, 1, etc.)
                                // Need to call setModelTypeValue(number)
                                setModelTypeValue(Number(data.value));
                            }}
                        >
                            <Radio
                                value={ModelType.Faster.toString()}
                                label={
                                    <>
                                        {ModelType[ModelType.Faster]}
                                        <br />
                                        <Text size={200}>Utilizes gpt-4o-mini</Text>
                                    </>
                                }
                            />
                            <Radio
                                value={ModelType.Balanced.toString()}
                                label={
                                    <>
                                        {ModelType[ModelType.Balanced]}
                                        <br />
                                        <Text size={200}>Utilizes gpt-4o</Text>
                                    </>
                                }
                            />
                            <Radio
                                value={ModelType.Better.toString()}
                                label={
                                    <>
                                        {ModelType[ModelType.Better]}
                                        <br />
                                        <Text size={200}>Utilizes gpt-4o currently (o1-preview coming soon)</Text>
                                    </>
                                }
                            />
                        </RadioGroup>
                    </div>
                </PopoverSurface>
            </Popover>
        </div>
    );
};
