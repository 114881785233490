import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, ProjectData, Projects, ProjectState } from './ProjectState';

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (
            state: ProjectState,
            action: PayloadAction<Projects>,
        ) => {
            state.projects = action.payload;
        },
        addProject: (state: ProjectState, action: PayloadAction<ProjectData>) => {
            const newId = action.payload.id;
            if (!(newId in state.projects)) {
                state.projects = { [newId]: action.payload, ...state.projects };
            }
        },
        editProject: (state: ProjectState, action: PayloadAction<ProjectData>) => {
            const id = action.payload.id;

            // Do not override folderOpen state
            let folderOpen = false;
            if (Object.keys(state.projects).includes(id)) {
                folderOpen = state.projects[id].folderOpen;
            }
            action.payload.folderOpen = folderOpen;

            state.projects[id].name = action.payload.name;
        },
        deleteProject: (state: ProjectState, action: PayloadAction<string>) => {
            const id = action.payload;
            const { [id]: _, ...rest } = state.projects;
            state.projects = rest;
        },
        openProjectFolder: (state: ProjectState, action: PayloadAction<string>) => {
            state.projects[action.payload].folderOpen = true;
        },
        closeProjectFolder: (state: ProjectState, action: PayloadAction<string>) => {
            state.projects[action.payload].folderOpen = false;
        },
    },
});

export const {
    setProjects,
    addProject,
    editProject,
    deleteProject,
    openProjectFolder,
    closeProjectFolder,
} = projectSlice.actions;

export default projectSlice.reducer;
