import {
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger
} from '@fluentui/react-components';
import { ArrowDownloadRegular, ArrowExitRegular, Pin20Filled, Pin20Regular } from '@fluentui/react-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useChat, useFile } from '../../../libs/hooks';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Delete16 } from '../../shared/BundledIcons';
import { ProjectSelectionDialog, ProjectSelectionDialogRef } from '../project/ProjectSelectionDialog';
import { ProjectSelectionSubMenu } from '../project/ProjectSelectionSubMenu';
import { DeleteChatDialog, DeleteChatDialogRef } from './dialogs/DeleteChatDialog';

interface IChatOptionsMenuProps {
    chatSessionId: string;
    rightClickToOpen?: boolean;
    TriggerComponent: React.ReactElement;
}

export const ChatOptionsMenu: React.FC<IChatOptionsMenuProps> = ({
    chatSessionId,
    rightClickToOpen,
    TriggerComponent
}) => {
    const chatSessions = useChat();

    const projectId = useAppSelector((state: RootState) => state.conversations.conversations[chatSessionId].projectId);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const pinned = useAppSelector((state: RootState) => state.conversations.conversations[chatSessionId]?.isPinned ?? false);

    const pinnedOnClick = (_ev: any) => {
        void chatSessions.toggleChatSessionPin(chatSessionId, !pinned);
    };

    const chatName = useAppSelector((state: RootState) => state.conversations.conversations[chatSessionId].title);
    const [isOriginalCreator, setIsOriginalCreator] = useState<boolean>(true);
    const chatCreator = useAppSelector((state: RootState) => state.conversations.conversations[chatSessionId].creatorUserId);
    const user = useAppSelector((state: RootState) => state.app.activeUserInfo);
    useEffect(() => {
        if (chatCreator && chatCreator === user?.id) {
            setIsOriginalCreator(true);
        } else {
            setIsOriginalCreator(false);
        }
    }, [chatCreator, user?.id]);

    const deleteChatDialogRef = React.createRef<DeleteChatDialogRef>();
    const projectSelectionDialogRef = React.createRef<ProjectSelectionDialogRef>();

    const { downloadFile } = useFile();

    const onDownloadBotClick = useCallback(() => {
        void chatSessions.downloadBot(chatSessionId).then((content) => {
            downloadFile(
                `chat-history-${chatName}-${new Date().toISOString()}.json`,
                JSON.stringify(content),
                'text/json',
            );
        });
    }, [chatSessions, chatSessionId, chatName, downloadFile]);

    const moveChatToProject = (projectId: string) => {
        void chatSessions.setChatSessionProject(chatSessionId, projectId);
    };

    const removeChatFromProject = () => {
        void chatSessions.setChatSessionProject(chatSessionId, '');
    }

    return (
        <>
            <Menu openOnContext={rightClickToOpen === true}>
                <MenuTrigger>{TriggerComponent}</MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem icon={pinned ? <Pin20Filled /> : <Pin20Regular />} onClick={pinnedOnClick}>
                            {pinned ? 'Unpin' : 'Pin'}
                        </MenuItem>
                        {isOriginalCreator && (
                            <MenuItem
                                icon={<Delete16 />}
                                onClick={() => {
                                    deleteChatDialogRef.current?.openDialog();
                                }}
                            >
                                Delete
                            </MenuItem>
                        )}
                        <MenuItem
                            icon={<ArrowDownloadRegular />}
                            onClick={onDownloadBotClick}
                        >
                            Download
                        </MenuItem>
                        {projectId && (
                            <MenuItem
                                icon={<ArrowExitRegular />}
                                onClick={() => {
                                    removeChatFromProject();
                                }}
                            >
                                Remove from project
                            </MenuItem>
                        )}
                        <ProjectSelectionSubMenu
                            menuText='Move chat to project'
                            projectSelectionAction={moveChatToProject}
                            currentProjectId={projectId}
                            projectSelectionDialogRef={projectSelectionDialogRef}
                        />
                    </MenuList>
                </MenuPopover>
            </Menu>
            <DeleteChatDialog chatId={chatSessionId} ref={deleteChatDialogRef} />
            <ProjectSelectionDialog
                currentProjectId={projectId}
                selectionAction={moveChatToProject}
                ref={projectSelectionDialogRef}
            />
        </>
    );
};
