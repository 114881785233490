import {
    Menu,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger
} from '@fluentui/react-components';
import { FolderRegular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { store } from '../../../redux/app/store';
import { ProjectData } from '../../../redux/features/projects/ProjectState';
import { ProjectSelectionDialogRef } from './ProjectSelectionDialog';

interface IProjectSelectionSubMenuProps {
    menuText: string;
    projectSelectionAction: (projectId: string) => void;
    // If this is submenu for an existing chat session, pass the current project ID to exclude it from the list
    currentProjectId: string | undefined;
    // Need ref passed as parameter since the submenu will go out of scope when the submenu closes
    projectSelectionDialogRef: React.RefObject<ProjectSelectionDialogRef>;
}

export const ProjectSelectionSubMenu: React.FC<IProjectSelectionSubMenuProps> = ({
    menuText,
    projectSelectionAction,
    currentProjectId,
    projectSelectionDialogRef,
}) => {
    const [open, setOpen] = useState(false);
    const shownProjectLimit = 10;
    const [shownProjects, setShownProjects] = useState<ProjectData[]>([]);

    useEffect(() => {
        if (!open) {
            return;
        }

        const projectOrder = Object.values(store.getState().projects.projects)
            .filter((project: ProjectData) => {
                return project.id !== currentProjectId;
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .slice(0, shownProjectLimit);

        setShownProjects(projectOrder);
    }, [open, currentProjectId]);

    return (
        <>
            <Menu
                open={open}
                onOpenChange={(_event, data) => {
                    setOpen(data.open);
                }}
            >
                <MenuTrigger>
                    <MenuItem>{menuText}</MenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        {shownProjects.length > 0 &&
                            <>
                                <MenuGroup>
                                    {shownProjects.map((project) => (
                                        <MenuItem
                                            key={project.id}
                                            onClick={() => {
                                                projectSelectionAction(project.id);
                                            }}
                                            icon={<FolderRegular />}
                                        >
                                            {project.name}
                                        </MenuItem>
                                    ))}
                                </MenuGroup>
                                <MenuDivider />
                            </>
                        }
                        <MenuGroup>
                            <MenuItem
                                onClick={() => {
                                    projectSelectionDialogRef.current?.openDialog();
                                }}
                            >
                                Other project...
                            </MenuItem>
                        </MenuGroup>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </>
    );
};
