import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { PersonaExploreButton } from './PersonaExploreButton';
import { PersonaListItem } from './PersonaListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
    },
    header: {
        marginTop: 0,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
    },
});

interface IPersonaListSectionProps {
    isConversationPaneFill: boolean;
    closeConversationPanel: () => void;
}

export const PersonaListSection: React.FC<IPersonaListSectionProps> = ({
    isConversationPaneFill,
    closeConversationPanel,
}) => {
    const classes = useClasses();
    const pinnedPersonas: string[] = useAppSelector((state: RootState) => state.personas.pinnedPersonas);
    const globallyPinnedPersonas: string[] = useAppSelector((state: RootState) => state.personas.globallyPinnedPersonas);
    //const allPinnedPersonas = [...new Set([...globallyPinnedPersonas, ...pinnedPersonas])];
    const pinnedButNotGlobalPersonas = pinnedPersonas.filter((id) => !globallyPinnedPersonas.includes(id));

    return (
        <>
            {globallyPinnedPersonas.length > 0 && (
                <>
                    <div className={classes.root}> 
                        <Text className={`${classes.header}`}>Global Pins</Text>
                        {globallyPinnedPersonas.map((id) => {
                            return (
                                <PersonaListItem
                                    id={id}
                                    key={id}
                                    isConversationPaneFill={isConversationPaneFill}
                                    closeConversationPanel={closeConversationPanel}
                                />
                            );
                        })}
                    </div>
                </>
            )}
            {pinnedButNotGlobalPersonas.length > 0 && (
                <>
                    <div className={classes.root}>
                        <Text className={`${classes.header}`}>Personal Pins</Text>
                        {pinnedButNotGlobalPersonas.map((id) => {
                            return (
                                <PersonaListItem
                                    id={id}
                                    key={id}
                                    isConversationPaneFill={isConversationPaneFill}
                                    closeConversationPanel={closeConversationPanel}
                                />
                            );
                        })}
                    </div>
                </>
            )}
            <PersonaExploreButton
                isConversationPaneFill={isConversationPaneFill}
                closeConversationPanel={closeConversationPanel}
            />
        </>
    );
};
