import { IProject } from '../models/Project';
import { BaseService } from './BaseService';

export class ProjectService extends BaseService {
    public getProjectsAsync = async (
        accessToken: string,
    ): Promise<IProject[]> => {
        const result = await this.getResponseAsync<IProject[]>(
            {
                commandPath: 'projects',
                method: 'GET',

            },
            accessToken,
        );
        return result;
    };

    public createProjectAsync = async (
        name: string,
        accessToken: string,
    ): Promise<IProject> => {
        const body = {
            name,
        };

        const result = await this.getResponseAsync<IProject>(
            {
                commandPath: 'projects',
                method: 'POST',
                body,
            },
            accessToken,
        );
        return result;
    };

    public editProjectAsync = async (
        projectId: string,
        name: string,
        accessToken: string,
    ): Promise<IProject> => {
        const body = {
            name,
        };

        const result = await this.getResponseAsync<IProject>(
            {
                commandPath: `projects/${projectId}`,
                method: 'PATCH',
                body,
            },
            accessToken,
        );
        return result;
    };

    public deleteProjectAsync = async (
        projectId: string,
        accessToken: string,
    ): Promise<object> => {

        const result = await this.getResponseAsync<object>(
            {
                commandPath: `projects/${projectId}`,
                method: 'DELETE',
            },
            accessToken,
        );
        return result;
    };
}
