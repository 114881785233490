import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { store } from '../../../redux/app/store';
import { ProjectData } from '../../../redux/features/projects/ProjectState';
import { SharedStyles } from '../../../styles';
import { ProjectCardClickable } from './ProjectCardClickable';

const useClasses = makeStyles({
    root: {
        width: '600px',
    },
    error: {
        color: '#d13438',
    },
    content: {
        '& > div:not(:first-child)': {
            marginTop: '.5rem',
        },
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
        tabList: {
        justifyContent: 'center',
    },
    cardsRoot: {
        marginTop: tokens.spacingVerticalXXXL,
        marginBottom: tokens.spacingVerticalXL,
    },
    cards: {
        gap: '16px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    tabDefaultTextContainer: {
        textAlign: 'center',
    },
});

interface IProjectSelectionDialogProps {
    currentProjectId: string | undefined;
    selectionAction: (projectId: string) => void;
}

export interface ProjectSelectionDialogRef {
    openDialog: () => void;
}

export const ProjectSelectionDialog = forwardRef<ProjectSelectionDialogRef, IProjectSelectionDialogProps>(({
    currentProjectId,
    selectionAction,
},
    ref
) => {
    const classes = useClasses();

    const [shownProjects, setShownProjects] = useState<ProjectData[]>([]);
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    
    // Expose setOpen method to parent components
    useImperativeHandle(ref, () => ({
        openDialog: () => {
            // Load project data from redux store
            const projectOrder = Object.values(store.getState().projects.projects)
                .filter((project: ProjectData) => {
                    return project.id !== currentProjectId;
                })
                .sort((a, b) => a.name.localeCompare(b.name));
            setShownProjects(projectOrder);

            setOpen(true);
        },
    }));

    const submit = (projectId: string) => {
        if (submitting) {
            return;
        }
        setSubmitting(true);

        selectionAction(projectId)

        setOpen(false);
    };

    return (
        <Dialog
            modalType="alert"
            open={open}
            onOpenChange={(_event, data) => {
                setOpen(data.open);
            }}
        >
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                appearance="subtle"
                                aria-label="close"
                                icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        Select A Project
                    </DialogTitle>
                    <DialogContent className={mergeClasses(classes.content, classes.scroll)}>
                        <div className={classes.cardsRoot}>
                            {shownProjects.length === 0
                                ?
                                <div className={classes.tabDefaultTextContainer}>
                                    <Label>
                                        You do not have any projects at this time.
                                    </Label>
                                </div>
                                :
                                <div className={classes.cards}>
                                    {shownProjects.map((project) => {
                                        return (
                                            <ProjectCardClickable
                                                key={project.id}
                                                projectId={project.id}
                                                projectOnClick={() => {
                                                    submit(project.id);
                                                }}
                                            />
                                        );
                                    })}         
                                </div>
                            }
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
});

ProjectSelectionDialog.displayName = 'ProjectSelectionDialog';